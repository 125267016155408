import React, { useEffect, useState, Fragment } from 'react';
import CheckMark from '../svg/CheckMark';
import { Listbox, Transition } from '@headlessui/react';
import { cn } from '~/utils/';
import {
  usePopup,
  usePricingPopup,
  subscriptionType,
  useCustomData,
  setWindowValue,
} from '~/components/helper/store';
import { Loader2 } from 'lucide-react';
import { getModelName } from '../helper/modelName';
import { toast } from 'react-toastify';
import { setCookieValue, showPricing } from '~/components/helper/global';

type SelectDropDownProps = {
  id?: string;
  title?: string;
  value: string;
  disabled?: boolean;
  setValue: (value: string) => void;
  availableValues: string[];
  showAbove?: boolean;
  showLabel?: boolean;
  containerClassName?: string;
  subContainerClassName?: string;
  className?: string;
  endpoint?: string;
};

function SelectDropDown({
  value,
  setValue,
  title = 'Model',
  disabled,
  availableValues,
  showAbove = false,
  showLabel = true,
  containerClassName,
  className,
  endpoint,
}: SelectDropDownProps) {
  const sState = usePopup((state) => state);
  const proState = subscriptionType((state) => state);
  const [isSimulating, setIsSimulating] = useState<boolean>(false);
  const pState = usePricingPopup((state) => state);
  const VITE_NON_BASIC_USER_MODEL =
    import.meta.env.VITE_NON_BASIC_USER_MODEL || 'gpt-3.5-turbo-0125';
  const openai_gpt4_model = import.meta.env.VITE_OPENAI_GPT4_MODEL || 'gpt-4-1106-preview';
  const openai_gpt3_model = import.meta.env.VITE_OPENAI_GPT3_MODEL || 'gpt-3.5-turbo-0125';
  const { hasFile, imagesPreview, imageUrls } = useCustomData((state) => state);

  const handleOptionClick = (option: string) => {
    if ((hasFile || imagesPreview?.length > 0 || imageUrls?.length > 0) && option !== 'gpt-4o') {
      const formattedModelName = getModelName(option);
      toast.dismiss();
      toast.error(`${formattedModelName} does not support images`);
      return;
    }

    setWindowValue('mxPanelTrack', option);
    setWindowValue('mxPanelClickLocation', 'chat-page');

    if (option.includes('gpt-4') && option !== 'gpt-4o' && option !== 'gpt-4o-mini') {
      if (
        (proState.isPro && option == openai_gpt4_model) ||
        (proState.isProMax && option == 'gpt-4-pro-max')
      ) {
        setValue(option);
      } else {
        showPricing(() => {
          pState.setShowPricingPopup(true);
        });
      }
    } else {
      setValue(option);
    }
  };

  useEffect(() => {
    const forUserInterval = setInterval(() => {
      if (typeof AIPRO_USER !== 'undefined' && AIPRO_USER && AIPRO_USER.subscription_type) {
        const subscriptionType = AIPRO_USER.subscription_type;

        if (subscriptionType) {
          if (
            subscriptionType.toString().toLowerCase() !== 'basic' &&
            subscriptionType !== null &&
            subscriptionType.toString() !== ''
          ) {
            if (endpoint === 'openAI') {
              setValue(VITE_NON_BASIC_USER_MODEL);
            }

            proState.setPro(true);
          }

          if (subscriptionType.toString().toLowerCase() === 'promax') {
            proState.setIsProMax(true);
          }

          if (subscriptionType.toString().toLowerCase() === 'enterprise') {
            proState.setIsEnterprise(true);
          }
        }
        clearInterval(forUserInterval);
      }
    }, 300);
  }, []);

  const isOpenSource = endpoint === 'Opensource';

  return (
    <div className={cn('flex items-center justify-center gap-2', containerClassName)}>
      <div className="relative w-full">
        <Listbox value={value} onChange={handleOptionClick} disabled={disabled}>
          {({ open }) => (
            <>
              <Listbox.Button
                className={cn(
                  'select-dropdown-btn relative flex w-full cursor-default flex-col rounded-md border border-black/10 bg-white py-2 pl-3 pr-10 text-left focus:border-green-600  focus:ring-1 focus:ring-green-600 dark:border-white/20 dark:bg-gray-800 sm:text-sm',
                  className,
                )}
                style={{
                  "padding": "0.5rem 1.75em 0.5rem 1rem"
                }}
                aria-label={'Select ' + title}
              >
                {showLabel && (
                  <span
                    className="block text-xs text-gray-700 dark:text-gray-500"
                    id="headlessui-listbox-label-:r1:"
                    data-headlessui-state=""
                    aria-label={title}
                    role="contentinfo"
                  >
                    {title}
                  </span>
                )}
                <span
                  className="inline-flex w-full truncate"
                  aria-label={getModelName(value)}
                  role="contentinfo"
                >
                  <span
                    className={cn(
                      'flex h-6 items-center gap-1 truncate text-sm text-gray-900 dark:text-white',
                      !showLabel ? 'text-xs' : '',
                    )}
                    aria-label={getModelName(value)}
                    role="contentinfo"
                  >
                    {!showLabel && (
                      <span
                        className="text-xs text-gray-700 dark:text-gray-500"
                        aria-label={title}
                        role="contentinfo"
                      >
                        {title}:
                      </span>
                    )}
                    {getModelName(value)}
                  </span>
                </span>
                <span
                  className="absolute inset-y-0 right-0 flex items-center pr-2"
                  role="img"
                  aria-label="dropdown icon"
                >
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="h-4 w-4  text-gray-400"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                    style={showAbove ? { transform: 'scaleY(-1)' } : {}}
                    role="img"
                    aria-label="dropdown icon"
                  >
                    <polyline
                      role="img"
                      aria-label="dropdown icon"
                      points="6 9 12 15 18 9"
                    ></polyline>
                  </svg>
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={React.Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className={showAbove ? 'bottom-full mb-3' : 'top-full mt-3'}
              >
                <Listbox.Options className="absolute z-10 mt-2 max-h-60 w-full overflow-auto rounded bg-white text-xs ring-1 ring-black/10  dark:bg-gray-800 dark:ring-white/20 dark:last:border-0">
                  {availableValues.map((option, i) =>
                    proState.isEnterprise == false ||
                    
                    (proState.isEnterprise && option != openai_gpt4_model && option != 'gpt-4-pro-max') ? (
                      <Listbox.Option
                        key={i}
                        value={option}
                        style={{ 
                          display: option === 'meta-llama/Llama-2-70b-chat-hf' ? 'none' : '',
                        }}
                        className={cn(
                          'listbox-option group relative flex h-[42px] cursor-pointer items-center overflow-hidden border-b border-black/10 px-3 text-gray-900 last:border-0 hover:bg-[#ECECF1] dark:border-white/20 dark:text-white dark:hover:bg-gray-700',
                          (hasFile || imagesPreview?.length > 0 || imageUrls?.length > 0) &&
                            option !== 'gpt-4o'
                            ? 'cursor-not-allowed  focus:ring-0 focus:ring-offset-0'
                            : '',
                        )}
                        onClick={() => handleOptionClick(option)}
                        aria-label={getModelName(option)}
                      >
                        <span className="flex w-full items-center justify-start gap-1.5 truncate">
                          <span
                            className={cn(
                              'flex h-6 items-center gap-1 text-gray-800 dark:text-gray-100',
                              option === value ? 'font-semibold' : '',
                            )}
                            aria-label={getModelName(option)}
                            role="option"
                          >
                            {isOpenSource ? (
                              <>
                              </>
                            ) : null}
                            <span
                              className={
                                (hasFile || imagesPreview?.length > 0 || imageUrls?.length > 0) &&
                                option !== 'gpt-4o'
                                  ? 'opacity-50'
                                  : ''
                              }
                            >
                              {getModelName(option)}
                            </span>
                          </span>
                          {proState.isPro == false && option == openai_gpt4_model ? (
                            <span
                              role="contentinfo"
                              aria-label="PRO"
                              className={cn(
                                'gptpro ml-2 rounded-md bg-green-500 px-1 py-0.5 text-xs text-white',
                                hasFile || imagesPreview?.length > 0 || imageUrls?.length > 0
                                  ? 'opacity-50'
                                  : '',
                              )}
                            >
                              PRO
                            </span>
                          ) : null}
                          {proState.isProMax == false && option == 'gpt-4-pro-max' && (
                            <span
                              role="contentinfo"
                              aria-label="PRO MAX"
                              className={cn(
                                'gptpro ml-2 rounded-md bg-green-500 px-1 py-0.5 text-xs text-white',
                                hasFile || imagesPreview?.length > 0 || imageUrls?.length > 0
                                  ? 'opacity-50'
                                  : '',
                              )}
                            >
                              PRO MAX
                            </span>
                          )}
                          {/* {option === value && (
                            <span
                              role="img"
                              aria-label="Checkmark"
                              className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-800 dark:text-gray-100"
                            >
                              <CheckMark />
                            </span>
                          )} */}
                        </span>
                      </Listbox.Option>
                    ) : null,
                  )}
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
}

export default SelectDropDown;
