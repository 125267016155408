import React, { useState } from 'react';
import { StopGeneratingIcon } from '~/components';
import { Settings } from 'lucide-react';
import { SetTokenDialog } from './SetTokenDialog';
import store from '~/store';
import { IoIosSend } from 'react-icons/io';

export default function SubmitButton({
  endpoint,
  submitMessage,
  handleStopGenerating,
  disabled,
  isSubmitting,
  isTyping,
  endpointsConfig,
}) {
  const [setTokenDialogOpen, setSetTokenDialogOpen] = useState(false);
  const { getToken } = store.useToken(endpoint);

  const isTokenProvided = endpointsConfig?.[endpoint]?.userProvide ? !!getToken() : true;
  const endpointsToHideSetTokens = new Set(['openAI', 'azureOpenAI', 'bingAI']);

  const clickHandler = (e) => {
    e.preventDefault();
    submitMessage();
  };

  const setToken = () => {
    setSetTokenDialogOpen(true);
  };

  if (isSubmitting) {
    return (
      <button
        onClick={handleStopGenerating}
        type="button"
        className="group absolute bottom-0 right-0 z-20 flex h-[100%] w-[50px] items-center justify-center bg-transparent p-0 text-gray-950 dark:text-white"
      >
        <div className="dark:group-hover:text-gray-white m-1 mr-0 rounded-md p-2 group-hover:bg-gray-100 group-disabled:hover:bg-transparent dark:group-hover:bg-gray-900 dark:group-disabled:hover:bg-transparent">
          <div className="flex h-[20px] w-[20px] items-center justify-center rounded-full border-2 border-gray-950 p-1 dark:border-white">
            <StopGeneratingIcon />
          </div>
        </div>
      </button>
    );
  } else if (!isTokenProvided && !endpointsToHideSetTokens.has(endpoint)) {
    return (
      <>
        <button
          onClick={setToken}
          type="button"
          className="text-gray-black group absolute bottom-0 right-0 z-20 flex h-[100%] w-auto items-center justify-center bg-transparent pr-1 dark:text-white"
        >
          <div className="flex items-center justify-center rounded-md text-xs group-hover:bg-gray-100 group-disabled:group-hover:bg-transparent dark:group-hover:bg-gray-900 dark:group-disabled:group-hover:bg-transparent">
            <div className="m-0 mr-0 flex items-center justify-center rounded-md p-2 sm:p-2">
              <Settings className="mr-1 inline-block h-auto w-[18px]" />
              Set Token First
            </div>
          </div>
        </button>
        <SetTokenDialog
          open={setTokenDialogOpen}
          onOpenChange={setSetTokenDialogOpen}
          endpoint={endpoint}
        />
      </>
    );
  } else {
    return (
      <button
        onClick={clickHandler}
        disabled={disabled || !isTyping}
        className="asksubmit group absolute bottom-0 right-2 z-20 flex h-[100%] w-[50px] items-center justify-center bg-transparent p-1 text-gray-500"
        aria-label="Submit Button"
      >
        <div
          className={`m-1 rounded-md p-[8px] text-white bg-[#2E3136] ${
            isTyping ? 'text-gray-950 dark:text-white' : ''
          }`}
          role="button"
          aria-label="Submit Button"
        >
          <IoIosSend className='text-[24px]'/>
        </div>
      </button>
    );
  }
}