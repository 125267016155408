import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import NewChat from './NewChat';
import Spinner from '../svg/Spinner';
import Pages from '../Conversations/Pages';
import { Conversations } from '../Conversations';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useGetConversationsQuery, useSearchQuery } from '~/data-provider';
import useDebounce from '~/hooks/useDebounce';
import store from '~/store';
import { useAuthContext } from '~/hooks/AuthContext';
import { ThemeContext } from '~/hooks/ThemeContext';
import { cn } from '~/utils/';
import { useMediaQuery } from '~/hooks';
import { LuPanelLeft } from 'react-icons/lu';
import AdvanceTools from './AdvanceTools';

export default function Nav({ navVisible, setNavVisible }) {
  const [isHovering, setIsHovering] = useState(false);
  const { isAuthenticated } = useAuthContext();
  const Theme = useContext(ThemeContext);
  const containerRef = useRef(null);
  const scrollPositionRef = useRef(null);

  const [conversations, setConversations] = useState([]);
  // current page
  const [pageNumber, setPageNumber] = useState(1);
  // total pages
  const [pages, setPages] = useState(1);

  // data provider
  const getConversationsQuery = useGetConversationsQuery(pageNumber, { enabled: isAuthenticated });

  // search
  const searchQuery = useRecoilValue(store.searchQuery);
  const isSearchEnabled = useRecoilValue(store.isSearchEnabled);
  const isSearching = useRecoilValue(store.isSearching);
  const { newConversation, searchPlaceholderConversation } = store.useConversation();

  // current conversation
  const conversation = useRecoilValue(store.conversation);
  const { conversationId } = conversation || {};
  const setSearchResultMessages = useSetRecoilState(store.searchResultMessages);
  const refreshConversationsHint = useRecoilValue(store.refreshConversationsHint);
  const { refreshConversations } = store.useConversations();

  const [isFetching, setIsFetching] = useState(false);

  const debouncedSearchTerm = useDebounce(searchQuery, 750);
  const searchQueryFn = useSearchQuery(debouncedSearchTerm, pageNumber, {
    enabled:
      !!debouncedSearchTerm && debouncedSearchTerm.length > 0 && isSearchEnabled && isSearching,
  });

  const [showVuzo, setShowVuzo] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const [srVisible, setSrVisible] = useState(true);

  useEffect(() => {
    const forUserInterval = setInterval(() => {
      if (typeof AIPRO_USER !== 'undefined' && AIPRO_USER && AIPRO_USER.subscription_type) {
        const subscriptionType = AIPRO_USER.subscription_type;

        if (subscriptionType) {
          setShowVuzo(true);
        }
        clearInterval(forUserInterval);
      }
    }, 300);
  }, []);

  useEffect(() => {
    if (isSmallScreen) {
      setNavVisible(false);
    } else {
      const savedNavVisible = localStorage.getItem('navVisible');
      setNavVisible(savedNavVisible !== null ? JSON.parse(savedNavVisible) : true);
    }
  }, [isSmallScreen]);

  const onSearchSuccess = (data, expectedPage) => {
    const res = data;
    setConversations(res.conversations);
    if (expectedPage) {
      setPageNumber(expectedPage);
    }
    setPages(res.pages);
    setIsFetching(false);
    searchPlaceholderConversation();
    setSearchResultMessages(res.messages);
  };

  useEffect(() => {
    //we use isInitialLoading here instead of isLoading because query is disabled by default
    if (searchQueryFn.isInitialLoading) {
      setIsFetching(true);
    } else if (searchQueryFn.data) {
      onSearchSuccess(searchQueryFn.data);
    }
  }, [searchQueryFn.data, searchQueryFn.isInitialLoading]);

  const clearSearch = () => {
    setPageNumber(1);
    refreshConversations();
    if (conversationId == 'search') {
      newConversation();
    }
  };

  const moveToTop = () => {
    const container = containerRef.current;
    if (container) {
      scrollPositionRef.current = container.scrollTop;
    }
  };

  const nextPage = async () => {
    moveToTop();
    setPageNumber(pageNumber + 1);
  };

  const previousPage = async () => {
    moveToTop();
    setPageNumber(pageNumber - 1);
  };

  useEffect(() => {
    if (getConversationsQuery.data) {
      if (isSearching) {
        return;
      }
      let { conversations, pages } = getConversationsQuery.data;
      if (pageNumber > pages) {
        setPageNumber(pages);
      } else {
        if (!isSearching) {
          conversations = conversations.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          );
        }
        setConversations(conversations);
        setPages(pages);
      }
    }
  }, [getConversationsQuery.isSuccess, getConversationsQuery.data, isSearching, pageNumber]);

  useEffect(() => {
    if (!isSearching) {
      getConversationsQuery.refetch();
    }
  }, [pageNumber, conversationId, refreshConversationsHint]);

  const toggleNavVisible = useCallback(() => {
    setNavVisible((prev: boolean) => !prev);

    if (!isSmallScreen) {
      localStorage.setItem('navVisible', JSON.stringify(!navVisible));
    }
  }, [isSmallScreen, navVisible]);

  return (
    <>
      <div
        className={cn(
          'min-h-nav fixed h-full w-auto md:relative md:inset-y-0',
          navVisible ? 'z-40' : '-z-10 md:z-40',
        )}
      >
        <div
          className={cn(
            'nav flex h-full w-[calc(100vw-60px)] max-w-[260px] flex-col overflow-hidden transition-[width] duration-200 ease-in-out',
            navVisible ? '' : 'w-0',
          )}
        >
          <div className="scrollbar-trigger relative flex h-full w-[calc(100vw-60px)] max-w-[260px] flex-1 items-start border-white/20">
            <nav className="relative flex h-full flex-1 flex-col space-y-2 px-4 py-6">
              <div className="flex items-center justify-center mb-3 md:hidden">
                <img
                  src="/assets/chatbotask-logo.svg"
                  alt="Chatbot Pro"
                  className="block dark:hidden"
                />
                <img
                  src="/assets/chatbotask-logo.svg"
                  alt="Chatbot Pro"
                  className="hidden dark:block"
                />
                <span className="ml-2 text-[26px] font-bold text-gray-900 dark:text-white">
                  ChatbotAsk
                </span>
              </div>
              <NewChat onAfterClick={() => setNavVisible(false)} />
              <div
                className={cn(
                  'scrollbar-transparent max-w-[228px] flex-1 flex-col overflow-y-auto',
                  {
                    'scrollbar-hide': !isHovering,
                  },
                )}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                ref={containerRef}
              >
                <div
                  className={cn('flex flex-col gap-1 text-sm', {
                    'h-full items-center justify-center':
                      getConversationsQuery.isLoading && pageNumber === 1,
                  })}
                >
                  {(getConversationsQuery.isLoading && pageNumber === 1) || isFetching ? (
                    <Spinner />
                  ) : (
                    <Conversations conversations={conversations} moveToTop={moveToTop} />
                  )}
                  <Pages
                    pageNumber={pageNumber}
                    pages={pages}
                    nextPage={nextPage}
                    previousPage={previousPage}
                  />
                </div>
              </div>
              <AdvanceTools />
            </nav>
          </div>
        </div>
        {(!isSmallScreen || (isSmallScreen && navVisible)) && (
          <button
            type="button"
            className={cn(
              'group absolute left-full top-9 md:top-11 max-w-768-1023:top-2 ml-2 z-10 block h-4 w-4 items-center justify-center rounded-md',
              Theme?.mode === 'dark'
                ? 'text-gray-500 hover:text-gray-400'
                : 'text-gray-400 hover:text-gray-500',
            )}
            onClick={() => {
              toggleNavVisible();
              setSrVisible(false);
            }}
            onMouseEnter={() => setSrVisible(true)}
            onMouseLeave={() => setSrVisible(false)}
          >
            {srVisible && (
              <span className="pointer-events-none absolute left-7 top-1/2 z-10 hidden h-max w-max -translate-y-1/2 overflow-visible whitespace-nowrap rounded bg-gray-300 px-2 py-1.5 text-sm text-black opacity-0 transition-opacity duration-200 ease-in [clip:_unset] group-hover:opacity-100 dark:bg-gray-900 dark:text-white/90 sm:block">
                <span className="absolute -left-1 top-1/2 -z-10 block h-2.5 w-2.5 -translate-y-1/2 rotate-45 bg-inherit" />
                {navVisible ? 'Close' : 'Open'} sidebar
              </span>
            )}
            <LuPanelLeft size={20} style={{strokeWidth: 1}} />
          </button>
        )}
      </div>
      {isSmallScreen && (
        <div
          className={cn(
            'fixed bottom-0 left-0 right-0 top-0 z-30 bg-[rgba(86,_88,_105,_0.75)] transition-opacity duration-200 ease-linear',
            navVisible ? ' pointer-events-auto opacity-100' : 'pointer-events-none opacity-0',
          )}
          onClick={toggleNavVisible}
        />
      )}
    </>
  );
}
