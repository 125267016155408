import { usePricingPopup, subscriptionType, setWindowValue, getWindowValue } from '../helper/store';
import axios from 'axios';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { IoMdClose } from 'react-icons/io';
import { cn, cookie } from '~/utils';
import PricingPopupCard from './PricingPopupCard';

import useEmblaCarousel from 'embla-carousel-react';

const VITE_REGISTER_URL_START =
  import.meta.env.VITE_REGISTER_URL_START ||
  'https://chatbotask.ai/register?ppg=51&reg_google=on&from_chatapp=on';

const VITE_REGISTER_URL_CHATAPP =
  import.meta.env.VITE_REGISTER_URL_CHATAPP ||
  'https://chatapp.chatbotask.ai/register?ppg=51&reg_google=on&from_chatapp=on';

const VITE_PAYMENT_PAGE_URL =
  import.meta.env.VITE_PAYMENT_PAGE_URL || 'https://chatbotask.ai/pay?ppg=51&from_chatapp=on';

const upgradeUrl = import.meta.env.VITE_UPGRADE_URL || 'https://chatbotask.ai/upgrade';

export default function PricingPopup() {
  const {
    type: sType,
    payment_interval: subscriptionInterval,
    isEnterprise,
    setType,
    setPaymentInterval,
    isProMax,
    isPro,
  } = subscriptionType((state) => state);
  const subscription: string = (sType || '').toLowerCase();
  const pState = usePricingPopup((state) => state);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (pState.pricingData.length === 0) {
      (async () => {
        const ppg = import.meta.env.VITE_PPG
          ? import.meta.env.VITE_PPG
          : isEnterprise
          ? '51'
          : '52';
        try {
          const response = await axios.post(
            `${import.meta.env.VITE_HOST_START}/api/get-pricing`,
            { ppg, from_upgrade: 1 },
            { headers: { 'content-type': 'application/x-www-form-urlencoded' } },
          );
          const output = response.data;
          if (output.success) {
            pState.setPricingData(output.data);
          }
        } catch (error) {
          console.log('error', error);
        }
      })();
    }
  });

  const getCookieValue = (key: string) => {
    const keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? decodeURIComponent(keyValue[2]) : null;
  };

  const redirectToPaymentPage = useCallback((plan_id: number) => {
    setWindowValue("mxadid", cookie.getValue('adid'));
    setWindowValue("mxemailid", cookie.getValue('emailid'));
    setWindowValue("mxkeyword", cookie.getValue('keyword'));
    setWindowValue("mxpmt", cookie.getValue('pmt') || 'pay2');
    setWindowValue("mxppg", cookie.getValue('ppg') || 59);
    setWindowValue("mxPanelTrack", cookie.getValue('mixPanelTrack') || getWindowValue("mxPanelTrack"));
    setWindowValue("mxPanelClickLocation", cookie.getValue('mixPanelClickLocation') || getWindowValue("mxPanelClickLocation"));
    const redirectRegistrationPage = (plan_id: number) => {
      const is_chatapp = getCookieValue('chatapp');

      if (document.referrer.includes('chatapp') || is_chatapp === 'on') {
        window.location.href = VITE_REGISTER_URL_CHATAPP + '&pricing=' + plan_id;
      } else {
        window.location.href = VITE_REGISTER_URL_START + '&pricing=' + plan_id;
      }
    };

    setTimeout(function () {
      if (typeof window.AIPRO_USER !== 'undefined' && window.AIPRO_USER && window.AIPRO_USER.email) {
        window.location.href = VITE_PAYMENT_PAGE_URL + '&pricing=' + plan_id;
      } else {
        redirectRegistrationPage(plan_id);
      }
    }, 2000);
  }, []);

  useEffect(() => {
    if (pState.showPricingPopup) {
      const mxPanelTrack: any = getWindowValue("mxPanelTrack");
      const model = cookie.getValue('mixPanelTrack')?.toLowerCase() || mxPanelTrack?.toLowerCase() || '';

      if (
        typeof window.AIPRO_USER !== 'undefined' &&
        window.AIPRO_USER &&
        window.AIPRO_USER.subscription_type
      ) {
        const subscription_type = window.AIPRO_USER.subscription_type;
        const payment_interval = window.AIPRO_USER.payment_interval;

        setType(subscription_type);
        setPaymentInterval(payment_interval);

        if (
          (isPro && !isProMax && model === 'gpt-4-pro-max') ||
          (subscription_type.toLocaleLowerCase() === 'basic' && !model?.includes('gpt-3')) ||
          (!isProMax && model?.includes('claude')) ||
          (!isProMax && model?.includes('dalle'))
        ) {
          if (upgradeUrl.includes('?')) {
            window.open(upgradeUrl + (isPro ? '&vjicon=1' : ''), '_blank');
          } else {
            window.open(upgradeUrl + (isPro ? '?vjicon=1' : ''), '_blank');
          }
          pState.setShowPricingPopup(false);
          return;
        }
      }

      if (document.body.style.pointerEvents === 'none') {
        document.body.style.pointerEvents = 'auto';
      }

      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [pState, isPro, isProMax, setPaymentInterval, setType]);

  const cardComponents = useMemo(() => {
    const components = [
      {
        label: 'FREE',
        display_txt1: '$0/Month',
        price: '0',
        plan_type: '',
        payment_interval: '',
      },
      ...pState.pricingData,
    ]
      .map((data, idx) => {
        const currentPlan =
          subscription?.toLowerCase() === data.plan_type?.toLowerCase() &&
          subscriptionInterval?.toLowerCase() === data.payment_interval?.toLowerCase();

        const isMonthly = data.payment_interval.toLowerCase() === 'monthly';
        const isNotCurrentSubscription =
          subscription.toLowerCase() !== data.plan_type.toLowerCase();
        const isCurrentSubscription =
          subscription.toLowerCase() === data.plan_type.toLowerCase() &&
          subscriptionInterval.toLowerCase() === data.payment_interval.toLowerCase();

        if (
          (isMonthly && (isNotCurrentSubscription || isCurrentSubscription)) ||
          (subscription === '' && data.payment_interval === '')
        ) {
          return (
            <PricingPopupCard
              key={idx}
              currentPlan={currentPlan}
              label={data.label}
              price={Number(data.price)}
              plan_type={data.plan_type}
              onClick={data.plan_id ? () => redirectToPaymentPage(data.plan_id) : undefined}
              display_txt2={data.display_txt2}
            />
          );
        }
      })
      .filter((c) => c !== undefined);
    return components;
  }, [pState.pricingData, redirectToPaymentPage, subscription, subscriptionInterval]);

  const [emblaRef, emblaApi] = useEmblaCarousel({ align: 'center', startIndex: 1 });
  const [selectedIndex, setSelectedIndex] = useState(1);

  useEffect(() => {
    if (emblaApi) {
      const onSelect = () => {
        setSelectedIndex(emblaApi.selectedScrollSnap());
      };
      emblaApi.on('select', onSelect);
    }
  }, [emblaApi]);

  if (!pState.pricingData.length) {
    return null;
  }
  return (
    <div
      id="pricing-modal"
      className={cn(
        'relative flex max-h-full items-center justify-center backdrop-blur-sm transition-opacity duration-200 ease-in md:flex-row',
        open ? 'opacity-100' : 'pointer-events-none opacity-0 delay-200',
      )}
      onClick={(e) => {
        if ((e.target as HTMLDivElement).id === 'pricing-modal') {
          pState.setShowPricingPopup(false);
        }
      }}
    >
      <div className="absolute right-0 top-0 mr-5 mt-5">
        <IoMdClose
          className="cursor-pointer text-2xl text-slate-200"
          onClick={() => {
            pState.setShowPricingPopup(false);
          }}
        />
      </div>

      <section className="embla w-full">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container flex lg:justify-center">
            {cardComponents.map((card, idx) => (
              <div
                className={cn(
                  'embla__slide mx-2 max-w-[256px] basis-auto transition first:ml-8 last:mr-8 max-w-768:translate-y-0 max-w-374-424:first:ml-16 max-w-374-424:last:mr-16 max-w-425-767:first:ml-[75px] max-w-425-767:last:mr-[75px] max-w-768-1023:first:ml-[50px] max-w-768-1023:last:mr-[50px]',
                  {
                    '-translate-y-5': selectedIndex === idx,
                  },
                )}
                key={idx}
              >
                {card}
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
