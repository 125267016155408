import React, { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { RouterProvider } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query';
import { ScreenshotProvider, ThemeProvider, useApiErrorBoundary } from './hooks';
import { router } from './routes';
import Helper from './helper';

const App = () => {
  const { setError } = useApiErrorBoundary();

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error) => {
        if (error?.response?.status === 401) {
          setError(error);
        }
      },
    }),
  });

  useEffect(() => {
    const forUserInterval = setInterval(() => {
      const vuzoImg = document.querySelector('.vuzo_img');
      const vuzoGplay = document.querySelector('.vuzo_gplay');
      const root = document.querySelector('#root');

      if (
        vuzoImg &&
        vuzoGplay &&
        root &&
        typeof displayVuzo === 'function' &&
        typeof displayGooglePlay === 'function'
      ) {
        const observerCallback = (mutationsList, observer) => {
          for (const mutation of mutationsList) {
            if (mutation.type === 'childList' && vuzoImg && vuzoGplay) {
              displayVuzo();
              displayGooglePlay();
              observer.disconnect();
            }
          }
        };

        const observer = new MutationObserver(observerCallback);

        if (root) {
          if (!vuzoImg || !vuzoGplay) {
            observer.observe(root, { childList: true, subtree: true });
          } else {
            displayVuzo();
            displayGooglePlay();
          }
        }

        observer.disconnect();
        clearInterval(forUserInterval);
      }
    }, 300);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <ThemeProvider>
          <RouterProvider router={router} />
          <Helper/>
          <ReactQueryDevtools initialIsOpen={false} position="top-right" />
        </ThemeProvider>
      </RecoilRoot>
    </QueryClientProvider>
  );
};

export default () => (
  <ScreenshotProvider>
    <App />
  </ScreenshotProvider>
);
