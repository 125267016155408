import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ChatContextType {
  showPoweredBy: boolean;
  setShowPoweredBy: (value: boolean) => void;
  resetPoweredBy: (messagesTree: any[]) => void; // Accept messagesTree as an argument
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const ChatProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [showPoweredBy, setShowPoweredBy] = useState<boolean>(false);

  const resetPoweredBy = (messagesTree: any[]) => {
    if (!messagesTree?.length) {
      setShowPoweredBy(false);
    }
  };

  return (
    <ChatContext.Provider value={{ showPoweredBy, setShowPoweredBy, resetPoweredBy }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = (): ChatContextType => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
};