import React, { useState } from 'react';
import {
  Sparkles,
  HelpCircle,
  Settings as SettingsIcon,
  Palette,
  User,
} from 'lucide-react';
import { FaUser } from 'react-icons/fa6';
import HeaderLink from './HeaderLink';
import { useLocalize } from '~/hooks';
import global from '~/utils/global';
import { stringToBoolean } from '~/utils/';
import { subscriptionType, usePricingPopup } from '~/components/helper/store';

export default function HeaderDropdown({ isVisible, showThemeModal, setIsDropdownVisible, setShowSettings }) {
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const localize = useLocalize();

  const proState = subscriptionType((state) => state);
  const sState = usePricingPopup((state) => state);

  const onClickThemes = () => {
    if (proState.isPro || proState.isProMax) {
      showThemeModal();
    } else {
      global.showPricing(() => {
        sState.setShowPricingPopup(true);
      });
    }
  };

  const navClickHandler = () => {
    const faq_page_link = import.meta.env.VITE_FAQ_PAGE;
    const open_to_new_tab = stringToBoolean(import.meta.env.VITE_FAQ_PAGE_OPEN_TO_NEW_TAB);

    if (faq_page_link) {
      if (open_to_new_tab) {
        window.open(faq_page_link, '_blank');
      } else {
        window.location.href = faq_page_link;
      }
    }
  };

  const myAccountHandler = () => {
    const myAccount_page_link = import.meta.env.VITE_DASHBOARD_LINK;
    const open_to_new_tab = stringToBoolean(import.meta.env.VITE_FAQ_PAGE_OPEN_TO_NEW_TAB);

    if (myAccount_page_link) {
      if (open_to_new_tab) {
        window.open(myAccount_page_link, '_blank');
      } else {
        window.location.href = myAccount_page_link;
      }
    }
  };

  const handleSettingsClick = () => {
    setShowSettings(true);
    if (setIsDropdownVisible) {
      setIsDropdownVisible(false); 
    }
  };

  return (
    <div
      className={`fixed z-50 right-5 top-12 mt-2 w-[259px] py-3 px-5 bg-white dark:bg-[#1A1D22] rounded-[20px] border dark:border-slate-500/10 transition-opacity duration-200 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0'
        }`}
    >
      <div className="block py-4 text-center">
        <div className="flex items-center">
          <div className="bg-[#015288] text-white text-3xl font-bold rounded-full px-4 py-2 flex items-center justify-center mx-auto w-14 h-14">
            {AIPRO_USER.email ? <FaUser size={35} role="img" aria-label="User" /> : 'G'}
          </div>
          <div className="px-3 flex flex-col justify-center">
            <div className="relative inline-block group">
              <span className="block font-semibold text-md text-black dark:text-white text-left max-w-[150px] truncate">
                {AIPRO_USER.email ? AIPRO_USER.email : 'Guest User'}
              </span>
              {AIPRO_USER.email && (
                <div className="absolute word-break break-words bottom-full left-3 transform -translate-x-1/2 mb-2 bg-gray-700 dark:bg-white text-white dark:text-black text-xs rounded py-1 px-2 whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {AIPRO_USER.email}
                </div>
              )}
            </div>
            <span className={`block font-semibold max-w-fit px-3 text-sm ${proState.isProMax ? 'bg-[#1976D2] text-white' : proState.isPro ? 'bg-[#56C596] text-white' : 'dark:bg-[#555555] bg-[#D4D3D8] dark:text-white text-black'} w-full rounded-full`}>
              {AIPRO_USER.email ? (proState.isProMax ? 'PRO MAX' : proState.isPro ? 'PRO' : '') : 'FREE'}
            </span>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200"></div>
      <ul className="py-2">
        {/* <HeaderLink
          svg={() => <User size={16} role="img" aria-label="My Account" />}
          text="My Account"
          className={activeItem === 'My Account' ? 'bg-[#ECECEC]' : ''}
          clickHandler={myAccountHandler}
        /> */}
        {(!proState.isProMax) && (
        <HeaderLink
          svg={() => <Sparkles size={16} role="img" aria-label="Upgrade to PRO" />}
          text={proState.isPro ? 'Upgrade to PRO MAX' : 'Upgrade to PRO'}
          pro={true}
          className={activeItem === 'Upgrade to PRO' ? 'bg-[#ECECEC]' : ''}
          clickHandler={() => {
            global.showPricing(() => {
              sState.setShowPricingPopup(true);
            });
          }}
          role="button"
          aria-label={proState.isPro ? 'Upgrade to PRO MAX' : 'Upgrade to PRO'}
        />
        )}
        <HeaderLink
          svg={() => <Palette size={16} role="img" aria-label="Themes" />}
          text={localize('com_nav_themes')}
          pro={!proState.isProMax}
          isPro={proState.isPro}
          isProMax={proState.isProMax}
          className={activeItem === 'Themes' ? 'bg-[#ECECEC]' : ''}
          clickHandler={onClickThemes}
          role="button"
          aria-label="Themes"
        />
        <HeaderLink
          svg={() => <HelpCircle size={16} role="img" aria-label="Help & FAQ" />}
          text={localize('com_nav_help_faq')}
          clickHandler={navClickHandler}
          role="link"
          aria-label="Help & FAQ"
        />
        <HeaderLink
          svg={() => <SettingsIcon size={16} role="img" aria-label="Settings" />}
          text={localize('com_nav_settings')}
          className={activeItem === 'Settings' ? 'bg-[#ECECEC]' : ''}
          clickHandler={handleSettingsClick}
          role="button"
          aria-label="Settings"
        />
      </ul>
    </div>
  );
}
